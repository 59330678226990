<template>
  <v-select
    label="Cuenta"
    :items="accountList"
    item-value="id"
    persistent-hint
    :rules="required"
    :loading="loading"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :return-object="returnObj ? true : false"
    :disabled="disabled"
    ref="auto"
  >
    <template v-slot:selection="{ item }">
      {{ item.code }} - {{ item._bank }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.code }} - {{ item._bank }}
    </template>
  </v-select>
</template>

<script>
import rules from "@/utils/form.rules";
import requests from "@/services/requests";

export default {
  props: ["value", "companyId", "reset", "returnObj", "isTransfer", "disabled"],
  data() {
    return {
      accountList: [],
      loading: false,
      required: [rules.required],
    };
  },
  methods: {
    displayList() {
      if (this.companyId > 0) {
        this.loading = true;
        let url = "api/accounts/account-bank/?";
        if (this.isTransfer) {
          url += "bybusiness=true&company__id=" + this.companyId;
        } else {
          url += "company__id=" + this.companyId;
        }
        requests.get(url).then((response) => {
          if (response.status == 200) {
            this.accountList = response.data;
          } else {
            console.log(response);
          }
          this.loading = false;
        });
      }
    },
  },
  watch: {
    companyId: function (newValue) {
      if (newValue > 0) {
        this.displayList();
      }
    },
    reset: function () {
      this.$refs.auto.reset();
    },
  },
  mounted() {
    this.displayList();
  },
};
</script>
